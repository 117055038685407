'use client'
import { note } from '@/utils/Note'
import React, { use, useEffect } from 'react'

export interface NotesProps {
  children: React.ReactNode
}

const Notes = (props:any) => {
  useEffect(() => {
    note(props.children)
  })

  return <></>
}

export default Notes