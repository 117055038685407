import Link from "next/link"

export interface InputProps {
  id: string
  label?: string
  type?: 'password' | 'email' | 'text' | 'date'
  value: string
  placeholder?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const Input = (props:InputProps) => {
  const labelClasses = [
    'text-left',
    props.label ? 'inline-block' : 'hidden',
    'w-3/12',
  ].join(' ')

  const inputClasses = [
    'inline-block',
    props.label ? 'w-8/12' : 'w-full',
    'border-theme-gray-300',
    'border-[1px]',
    'rounded-md',
    'py-3',
    'px-4',
    'placeholder-theme-gray-500'
  ].join(' ')


  return (
    <div className={`block my-2`}>
      <label 
        className={labelClasses} 
        htmlFor={`input-${props.id}`}
      >
        {props.label ?? ''}
      </label>
      <input 
        id={`input-${props.id}`}
        type={props.type ?? 'text'} 
        value={props.value ?? ''}
        placeholder={props.placeholder ?? ''}
        className={inputClasses}
        onChange={props.onChange}
      />
    </div>
  )
}

export interface ButtonProps {
  onClick?: () => void
  href?: string
  type?: 'button' | 'submit' | 'link'
  children: React.ReactNode
  className?: string
  theme?: 'primary' | 'secondary' | 'primary-small' | 'secondary-small' | 'disabled'
}

export const Button = (props: ButtonProps) => {
  const buttonClasses = [
    'font-bold',
    'rounded',
    'w-full',
    'block',
    'flex-shrink'
  ]

  switch (props.theme) {
    case 'disabled':
      buttonClasses.push(`bg-theme-gray-100 border-theme-gray-200 border-[1px] border-solid text-theme-gray-500 py-3 px-4`)
      break
    case 'secondary-small':
      buttonClasses.push(`bg-theme-gray-100 border-theme-gray-200 border-[1px] border-solid text-theme-brand-700 hover:shadow-xl text-xs py-1 px-3`)
      break
    case 'secondary':
      buttonClasses.push('bg-theme-gray-100 border-theme-gray-200 border-[1px] border-solid text-theme-brand-700 hover:shadow-xl py-3 px-4')
      break
    case 'primary-small':
      buttonClasses.push(`bg-theme-brand-500 text-white hover:shadow-xl text-xs py-1 px-3`)
      break
    case 'primary':
    default:
      buttonClasses.push(`bg-theme-brand-600 text-white hover:shadow-xl py-3 px-4`)
      break
  }

  if (props.className) {
    buttonClasses.unshift(props.className)
  }

  if (props.type === 'link' && !props.href) {
    throw new Error('Link buttons must have an href prop')
  }
  
  return (
    <>
      { props.type === 'link' ?
        <Link
          href={props.href!}
          className={`${buttonClasses.join(' ')} inline-block`}
        >
          {props.children}
        </Link>
        : 
        <button
          type={props.type ?? 'button'}
          disabled={props.theme === 'disabled'}
          className={buttonClasses.join(' ')}
          onClick={props.onClick}
        >
          {props.children}
        </button>
      }
    </>
  )
}

export interface CheckBoxProps {
  id: string
  children: React.ReactNode
  checked: boolean
  className?: string
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

export const CheckBox = (props: CheckBoxProps) => {
  // Grab all the classes relevant to the DIV element
  const validClassesDiv = props?.className?.split(' ').filter(c => [
      c.match(/^p[lrtb]?-[^- ]*$/) ? c : '', // match p-[anything]
    ].includes(c)
  ).join(' ')

  const validClassesLabel = props?.className?.split(' ').filter(c => [
      c.match(/text-.+/) ? c : '',
    ].includes(c)
  ).join(' ')

  console.log(validClassesDiv, validClassesLabel)

  return (
    <div className={`flex flex-row my-2 items-center ${validClassesDiv}`}>
      <input 
        id={`input-${props.id}`}
        type="checkbox" 
        checked={props.checked}
        onChange={props.onChange}
      />
      <label 
        htmlFor={`input-${props.id}`}
        className={`inline-block ml-2 ${validClassesLabel}`}
      >
        {props.children}
      </label>
    </div>
  )
}